<template>
	<CModal
		ref="modal"
		:show.sync="isShow"
		centered
		data-test-id="attribute-sets-modal"
		@update:show="close"
	>
		<span>{{ title }}</span>
		<template #header-wrapper>
			<ModalHeaderWrapper
				:title="title"
				@onClose="close"
			/>
		</template>
		<template #default>
			<CForm @submit.prevent="handleSubmit">
				<CInput
					ref="input-text"
					v-model="$v.inputText.$model"
					:is-valid="!$v.inputText.$error && null"
					autofocused
					type="text"
					data-test-id="attribute-input-text"
					@input="handleChangedName"
				>
					<template #invalid-feedback>
						<div
							v-if="errorNameMessage"
							class="invalid-feedback"
							data-test-id="name-invalid-feedback"
						>
							{{ errorNameMessage }}
						</div>
					</template>
				</CInput>
			</CForm>
		</template>
		<template #footer>
			<CButton
				:disabled="isSubmitting"
				class="modal-button transparent mr-3"
				color="tertiary"
				data-test-id="cancel-button"
				@click="close"
			>
				Cancel
			</CButton>
			<CButton
				:disabled="$v.$invalid || isSubmitting"
				class="modal-button transparent"
				color="primary"
				data-test-id="primary-button"
				@click="handleSubmit"
			>
				<span>
					{{ isSubmitting ? "Submitting" : primaryButtonText }}
				</span>
			</CButton>
		</template>
	</CModal>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import ModalHeaderWrapper from './ModalHeaderWrapper.vue';

export default {
	name: 'ModalCreateAttribute',
	components: {
		ModalHeaderWrapper,
	},
	validations() {
		return {
			inputText: {
				required,
				maxLenght: maxLength(255),
				duplicateName: () => !this.isErrorDuplicateName,
			},
		};
	},
	props: {
		title: {
			type: String,
			default: 'Untitled',
		},
		primaryButtonText: {
			type: String,
			default: 'Create',
		},
		handleCreate: {
			type: Function,
			default: () => {},
		},
		isErrorDuplicateName: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isShow: false,
			isSubmitting: false,
			inputText: '',
		};
	},
	computed: {
		errorNameMessage() {
			if (this.$v.inputText.$invalid) {
				if (!this.$v.inputText.required) {
					return this.$t('page.attributeKeys.create.error.required');
				} else if (!this.$v.inputText.maxLenght) {
					return this.$t('page.attributeKeys.create.error.maxLengthName', { length: this.$v.inputText.$params.maxLenght.max });
				} else if (!this.$v.inputText.duplicateName) {
					return this.$t('page.attributeKeys.create.error.duplicateName', { name: this.inputText });
				}
			}
			return null;
		},
	},
	methods: {
		async open() {
			this.isShow = true;
			await this.$nextTick();
			this.$refs['input-text'].$el.querySelector('input').focus();
		},
		close() {
			this.$v.$reset();
			this.inputText = '';
			this.isShow = false;
		},
		async handleSubmit() {
			this.$v.$touch();
			if (this.$v.$invalid) return;
			this.isSubmitting = true;
			try {
				await this.handleCreate(this.inputText);

				if (!this.isErrorDuplicateName) {
					this.$v.$reset();
					this.isShow = false;
					this.inputText = '';
					this.$emit('onSuccess');
				}
			} catch (e) {
				this.$emit('onFailure');
			}
			this.isSubmitting = false;
		},
		handleChangedName() {
			// Reset error
			this.$emit('onResetError');
			this.$v.$reset();

			this.$v.inputText.$touch();
		},
	},
};
</script>
