<template>
	<div>
		<CRow>
			<CCol class="btn-wrapper">
				<CButton
					block
					class="transparent"
					color="secondary"
					data-test-id="add-attribute-btn"
					@click="$refs['modal-add'].open()"
				>
					<CIcon class="icon-left" name="cil-plus" />
					Add
				</CButton>
			</CCol>
			<CCol>
				<form
					class="search-form"
					@submit.prevent="handleSearch"
				>
					<CInput
						v-model.trim="queryParams.q"
						data-test-id="attribute-keys-search"
						type="text"
						placeholder="Search"
					>
						<template #prepend-content>
							<button
								type="submit"
								class="btn-search"
							>
								<FontAwesomeIcon :icon="['far', 'search']" />
							</button>
						</template>
					</CInput>
				</form>
			</CCol>
		</CRow>
		<BaseTable
			:is-loading="list.isLoading"
			:fields="FIELDS"
			:items="attributeKeys"
			:pagination="{
				pages: list.meta.lastPage,
				activePage: list.meta.currentPage
			}"
			class="table-custom"
			striped
			@onPaginationClick="handlePageChange"
		>
			<template #no-items-view>
				<div class="empty-table-element">
					<p class="subtitle">
						{{ $t("global.searchNotFound", { field: "attribute key" }) }}
					</p>
				</div>
			</template>
			<template #name="{item}">
				<CRow>
					<CCol>{{ item }}</CCol>
				</CRow>
			</template>
			<template #id="{item}">
				<div class="text-right">
					<CButton
						class="transparent p-0"
						@click.prevent="openModalDelete(item)"
					>
						<CIcon
							class="icon-trash"
							name="cil-trash"
						/>
					</CButton>
				</div>
			</template>
		</BaseTable>
		<ModalCreateAttribute
			ref="modal-add"
			:handle-create="createAttributeKey"
			:is-error-duplicate-name="create.isErrorDuplicateName"
			title="Add new attribute"
			@onSuccess="handleCreateSuccess"
			@onResetError="resetErrorCreateAttributeKey"
		/>
		<ModalRemoveAttribute
			ref="modal-remove"
			:handle-remove="deleteAttributeKey.bind(null,selectedKeyId)"
			title="Remove this attribute?"
			description="Removing this attribute will also remove it and all its values from all products."
			@onSuccess="handleDeleteSuccess"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { cleanObject, randomSearchString } from '../assets/js/helpers';
import ModalCreateAttribute from '../components/ModalCreateAttribute.vue';
import ModalRemoveAttribute from '../components/ModalRemoveAttribute.vue';

const FIELDS = [
	{ key: 'name', label: 'Attribute', class: 'col-name' },
	{ key: 'id', label: '', class: 'col-id' },
];

export default {
	name: 'AttributeKeys',
	components: {
		ModalCreateAttribute,
		ModalRemoveAttribute,
	},
	data() {
		return {
			FIELDS,
			selectedKeyId: null,
			queryParams: {
				q: this.$route.query.q || null,
				page: Number(this.$route.query.page) || null,
				r: randomSearchString(),
			},
		};
	},
	computed: {
		...mapState('attributeKeys', {
			list: 'list',
			create: 'create',
		}),
		...mapGetters({
			attributeKeys: 'attributeKeys/attributeList',
		}),
	},
	created() {
		this.getAttributeKeys(this.queryParams);
	},
	methods: {
		...mapActions({
			getAttributeKeys: 'attributeKeys/getAttributeKeys',
			createAttributeKey: 'attributeKeys/createAttributeKey',
			deleteAttributeKey: 'attributeKeys/deleteAttributeKey',
			resetErrorCreateAttributeKey: 'attributeKeys/resetErrorCreateAttributeKey',
		}),
		updateUrlParams() {
			const query = cleanObject(JSON.parse(JSON.stringify(this.queryParams)));
			this.$router.push({ query });
		},
		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},
		async handleCreateSuccess() {
			this.queryParams.q = '';
			this.queryParams.page = null;
			await this.getAttributeKeys(this.queryParams);
		},
		async handleDeleteSuccess() {
			this.selectedKeyId = null;
			this.queryParams.q = '';
			this.queryParams.page = null;
			await this.getAttributeKeys(this.queryParams);
		},
		openModalDelete(attributeKeyId) {
			this.selectedKeyId = attributeKeyId;
			this.$refs['modal-remove'].open();
		},
	},
};
</script>

<style lang="scss" scoped>
	.btn-wrapper {
		max-width: rem(132);
	}

	.btn-search {
		background: transparent;
		box-shadow: none;
		border: none;
		padding: 0;
	}

	.icon-trash {
		color: $color-black-25;
	}

	::v-deep .col-name {
		z-index: 1;
	}
</style>
